import jwtDecode from 'jwt-decode';
import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';

import Spinner from './components/UI/SpinnerM';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

import { timezone } from './helpers/url';
import { axios_http } from './helpers/base';

const Api = React.lazy(() => import('./components/Api'));
const RedirectPlaceholder = React.lazy(() => import('./components/RedirectPlaceholder'));
const Kpce = React.lazy(() => import('./components/Kpce'));
const Login = React.lazy(() => import('./containers/Login'));
const Logout = React.lazy(() => import('./containers/Logout'));
const PasswordResetInit = React.lazy(() => import('./containers/PasswordResetInit'));
const PasswordReset = React.lazy(() => import('./containers/PasswordReset'));
const Home = React.lazy(() => import('./containers/Home'));
const AccountingReimbursement = React.lazy(() => import('./containers/Accounting/AccountingReimbursement'));
const RegistrationImportProspStudent = React.lazy(() => import('./containers/Registration/RegistrationImportProspStudent'));
const RegistrationProspStudentBaodao = React.lazy(() => import('./containers/Registration/RegistrationProspStudentBaodao'));
const RegistrationProspStudentList = React.lazy(() => import('./containers/Registration/RegistrationProspStudentList'));
const RegistrationProspStudent = React.lazy(() => import('./containers/Registration/RegistrationProspStudentSingle'));
const RegistrationStudentAdd = React.lazy(() => import('./containers/Registration/RegistrationStudentAdd'));
const RegistrationExport = React.lazy(() => import('./containers/Registration/RegistrationExport'));
const RegistrationPrint = React.lazy(() => import('./containers/Registration/RegistrationPrint'));
const RegistrationStudentList = React.lazy(() => import('./containers/Registration/RegistrationStudentList'));
const RegistrationStudentSingle = React.lazy(() => import('./containers/Registration/RegistrationStudentSingle'));
const RegistrationStudentSmsFillInfo = React.lazy(() => import('./containers/Registration/RegistrationStudentSmsFillInfo'));
const RegistrationStudentBanjiList = React.lazy(() => import('./containers/Registration/RegistrationStudentBanjiList'));
const RegistrationScholarshipAdmin = React.lazy(() => import('./containers/Registration/RegistrationScholarshipAdmin'));
const ServiceGeneralMsgDelivery = React.lazy(() => import('./containers/ServiceGeneral/ServiceGeneralMsgDelivery'));
const ServiceGeneralDocApply = React.lazy(() => import('./containers/ServiceGeneral/ServiceGeneralDocApply'));
const KpceAdmin = React.lazy(() => import('./containers/Kpce/KpceAdmin'));
const KpceToukeImport = React.lazy(() => import('./containers/Kpce/KpceToukeImport'));
const AdmissionProspStudentImport = React.lazy(() => import('./containers/Admission/AdmissionProspStudentImport'));
const AdmissionProspStudentList = React.lazy(() => import('./containers/Admission/AdmissionProspStudentList'));
const AdmissionProspStudentSem1 = React.lazy(() => import('./containers/Admission/AdmissionProspStudentSem1'));
const AdmissionProspStudentSingle = React.lazy(() => import('./containers/Admission/AdmissionProspStudentSingle'));
const AdmissionProspStudentAdmissionQuery = React.lazy(() => import('./containers/Admission/AdmissionProspStudentAdmissionQuery'));
const AdmissionExport = React.lazy(() => import('./containers/Admission/AdmissionExport'));
const StaffList = React.lazy(() => import('./containers/StaffList'));
const StaffSingle = React.lazy(() => import('./containers/StaffSingle'));
const SmsList = React.lazy(() => import('./containers/Sms/SmsList'));
const SmsFree = React.lazy(() => import('./containers/Sms/SmsFree'));
const HsiabeiAdmin = React.lazy(() => import('./containers/Hsiabei/HsiabeiAdmin'));
const HsiabeiChecker = React.lazy(() => import('./containers/Hsiabei/HsiabeiChecker'));
const HsiabeiMain = React.lazy(() => import('./containers/Hsiabei/HsiabeiMain'));
const HouqinAssetMain = React.lazy(() => import('./containers/Houqin/HouqinAssetMain'));
const HouqinAssetOwner = React.lazy(() => import('./containers/Houqin/HouqinAssetOwner'));
const HouqinVendorMain = React.lazy(() => import('./containers/Houqin/HouqinVendorMain'));
const HouqinWeixiuMain = React.lazy(() => import('./containers/Houqin/HouqinWeixiuMain'));
const HumanResourceZihupingAdmin = React.lazy(() => import('./containers/HumanResource/HumanResourceZihupingAdmin'));
const HumanResourceZihupingSurvey = React.lazy(() => import('./containers/HumanResource/HumanResourceZihupingSurvey'));
const AdminDataManage = React.lazy(() => import('./containers/Admin/AdminDataManage'));
const AdminGuardianEvent = React.lazy(() => import('./containers/Admin/AdminGuardianEvent'));
const StudentZhiping = React.lazy(() => import('./containers/Student/StudentZhiping'));
const StudentGuardianEventResult = React.lazy(() => import('./containers/Student/StudentGuardianEventResult'));
const StudentBarcode = React.lazy(() => import('./containers/Student/StudentBarcode'));
const StudentScholarshipApply = React.lazy(() => import('./containers/Student/StudentScholarshipApply'));
const ExperimentReimbursementPlan = React.lazy(() => import('./containers/Experiment/ExperimentReimbursementPlan'));

const App = (props) => {
    // this prevents user from accessing using IP
    if (window.location.hostname === '35.221.204.155') {
        window.location.href = 'http://staff.kpvs.tp.edu.tw';
    }
    let history = useHistory();
    // let path = window.location.pathname.split('/');

    const [state_uuid_user, set_uuid_user] = useState('');
    const [state_name_full_zh, set_name_full_zh] = useState('');
    const [state_is_live_hr_zhp_event, set_is_live_hr_zhp_event] = useState(false);
    const [state_login_redirect_path, set_login_redirect_path] = useState('');
    const [state_view_sidebar, set_view_sidebar] = useState(false);
    // eslint-disable-next-line
    const [state_view_change, set_view_change] = useState(true);

    useEffect(async () => {
        let token = localStorage.getItem('token');
        // can't user check_login() here because !token is OK
        if (!token) {
            return;
        }
        if (token) {
            let token_decoded = jwtDecode(token);
            if (token_decoded.time_expiry) {
                if (new Date(token_decoded.time_expiry) > new Date()) {
                    set_uuid_user(token_decoded.uuid);
                    set_name_full_zh(token_decoded.name_full_zh);
                    let response = await axios_http('post', '/hr_zhp/get_user_in_live_event_list', true, {
                        data: {
                            uuid: token_decoded.uuid,
                            date_live: new Date().toLocaleString('sv-SE', {timeZone:timezone}).split(' ')[0],
                        },
                    });
                    if (response && response.data && response.data.results && response.data.results.length) {
                        set_is_live_hr_zhp_event(true);
                    }
                    return;
                }
            }
            localStorage.removeItem('token');
        }
        // alert('登入時效過期，請重新登入');
        history.push('/logout');
    });

    const handle_state_update = (name, value) => {
        switch (name) {
            case 'view_change':
                // this is just a state update call so things like topbar page titles will update
                set_view_change(prev => {
                    return !prev;
                });
            break;
            case 'uuid_user':
                set_uuid_user(value);
            break;
            case 'login_redirect_path':
                set_login_redirect_path(value);
            break;
            case 'view_sidebar':
                set_view_sidebar(prev => {
                    return !prev;
                });
            break;
            case 'back':
                history.goBack();
                setTimeout(() => {
                    set_view_change(prev => {
                        return !prev;
                    });    
                }, 500);
            break;
            default:
        }
    };

    let routes = (
        <Switch>
            <Route path='/password_reset/:token' render={ () => (<Suspense fallback={<div><Spinner /></div>}>
                <PasswordReset />
            </Suspense>) } />
            <Route path='/password_reset_init' render={ () => (<Suspense fallback={<Spinner />}>
                <PasswordResetInit />
            </Suspense>) } />


            <Route path='/accounting_reimbursement' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AccountingReimbursement on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/registration_student_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationStudentList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_view/:uuid' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationStudentSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_edit/:uuid' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationStudentSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_add' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationStudentAdd on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_banji_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationStudentBanjiList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_sms_fill_info' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationStudentSmsFillInfo />
            </Suspense>) } />
            <Route path='/registration_student_fill_info' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationStudentSingle />
            </Suspense>) } />
            <Route path='/registration_student_export' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationExport on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_student_print' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationPrint on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/registration_import_prosp_student' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationImportProspStudent on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            {/* this is for sending the sms text */}
            <Route path='/registration_prosp_student_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationProspStudentList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            {/* this is for baodao after receiving the sms text */}
            <Route path='/registration_prosp_student_baodao' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationProspStudentBaodao />
            </Suspense>) } />
            {/* this is for filling out the info after receiving the sms text */}
            <Route path='/registration_prosp_student_fill_info' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationProspStudent />
            </Suspense>) } />
            {/* this is for registration team to view the prosp_student after they've filled out the info */}
            <Route path='/registration_prosp_student_view' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationProspStudent />
            </Suspense>) } />
            {/* this is for registration team to edit the prosp_student after they've filled out the info */}
            <Route path='/registration_prosp_student_edit' render={ () => (<Suspense fallback={<Spinner />}>
                <RegistrationProspStudent />
            </Suspense>) } />
            <Route path='/registration_scholarship_admin' render={ () => (<Suspense fallback={ <Spinner /> }>
                <RegistrationScholarshipAdmin on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/service_general_msg_delivery' render={ () => (<Suspense fallback={<Spinner />}>
                <ServiceGeneralMsgDelivery />
            </Suspense>) } />
            <Route path='/service_general_doc_apply' render={ () => (<Suspense fallback={<Spinner />}>
                <ServiceGeneralDocApply />
            </Suspense>) } />


            <Route path='/kpce_admin' render={ () => (<Suspense fallback={<Spinner />}>
                <KpceAdmin />
            </Suspense>) } />
            <Route path='/kpce_touke_import' render={ () => (<Suspense fallback={<Spinner />}>
                <KpceToukeImport />
            </Suspense>) } />


            <Route path='/admission_prosp_student_import' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionProspStudentImport on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/admission_prosp_student_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionProspStudentList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/admission_prosp_student_sem1' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionProspStudentSem1 on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/admission_prosp_student_add' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionProspStudentSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/admission_prosp_student_fill_info' render={ () => (<Suspense fallback={<Spinner />}>
                <AdmissionProspStudentSingle />
            </Suspense>) } />
            <Route path='/admission_export' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionExport on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/招生資訊查詢' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdmissionProspStudentAdmissionQuery on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/staff_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StaffList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/staff_view/:uuid' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StaffSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/staff_edit/:uuid' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StaffSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/staff_add' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StaffSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/asset_mgmt' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HouqinAssetMain on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/asset_owner' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HouqinAssetOwner on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/weixiu_admin' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HouqinWeixiuMain on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/vendor_mgmt' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HouqinVendorMain on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            {/* <Route path='/cashier_admission' render={ () => (<Suspense fallback={ <Spinner /> }>
                <CashierAdmission on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} /> */}


            <Route path='/experiment_reimbursement_plan' render={ () => (<Suspense fallback={ <Spinner /> }>
                <ExperimentReimbursementPlan on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/sms_list' render={ () => (<Suspense fallback={ <Spinner /> }>
                <SmsList on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/sms_free' render={ () => (<Suspense fallback={ <Spinner /> }>
                <SmsFree on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/hsiabei_checker' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HsiabeiChecker on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/hsiabei_admin' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HsiabeiAdmin on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/hsiabei' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HsiabeiMain on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/weixiu' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HouqinWeixiuMain on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            

            <Route path='/zihuping_admin' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HumanResourceZihupingAdmin on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/zihuping_survey' render={ () => (<Suspense fallback={ <Spinner /> }>
                <HumanResourceZihupingSurvey on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/admin_data_manage' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdminDataManage on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/admin_guardian_event_manage' render={ () => (<Suspense fallback={ <Spinner /> }>
                <AdminGuardianEvent on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/student_zhiping' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StudentZhiping on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/student_guardian_event_result' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StudentGuardianEventResult on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/student_barcode' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StudentBarcode on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/student_scholarship_apply' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StudentScholarshipApply on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />


            <Route path='/profile' render={ () => (<Suspense fallback={ <Spinner /> }>
                <StaffSingle on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Route path='/logout' render={ () => (<Suspense fallback={ <Spinner /> }>
                <Logout
                    on_view_change={() => handle_state_update('view_change')}
                    on_redirect_path_update = { (path) => handle_state_update('login_redirect_path', path) }
                    on_uuid_user_update = { (uuid) => handle_state_update('uuid_user', uuid) }
                />
            </Suspense>)} />
            <Route path='/login' render={ () => (<Suspense fallback={ <Spinner /> }>
                <Login
                    on_view_change={() => handle_state_update('view_change')} 
                    on_redirect_path_update = { (path) => handle_state_update('login_redirect_path', path) }
                    on_uuid_user_update = { (uuid) => handle_state_update('uuid_user', uuid) }
                    uuid_user={ state_uuid_user }
                    redirect_path = { state_login_redirect_path }
                />
            </Suspense>)} />


            <Route path='/r' render={ () => (<Suspense fallback={<Spinner />}>
                <Api />
            </Suspense>) } />
            <Route path='/redirect' render={ () => (<Suspense fallback={<Spinner />}>
                <RedirectPlaceholder />
            </Suspense>) } />
            <Route path='/kpce' render={ () => (<Suspense fallback={<Spinner />}>
                <Kpce />
            </Suspense>) } />


            <Route path='/' render={ () => (<Suspense fallback={ <Spinner /> }>
                <Home on_view_change={() => handle_state_update('view_change')} />
            </Suspense>)} />
            <Redirect to="/logout" />
        </Switch>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Sidebar 
                open={state_view_sidebar} 
                on_click_backdrop={() => handle_state_update('view_sidebar', null)}
                is_live_hr_zhp_event={state_is_live_hr_zhp_event}
            />
            <Topbar 
                on_click_bar_icon={() => {handle_state_update('view_sidebar', null)}} 
                on_click_back={() => {handle_state_update('back')}}
                on_view_change={() => handle_state_update('view_change')} 
            />
            {routes}
            <Footer />
        </ThemeProvider>
    );
}

export default App;