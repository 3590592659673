import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    list_page: 0,
    list_rows_per_page: 20,
    list_query: {
        // year: ' ',
        // quarter: 0,
        query: '',
    },
    list_order_by: 'username ASC',
};

const set_staff_list_page = (state, action) => {
    return updateObject(state, {
        list_page: action.data,
    });
};

const set_staff_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        list_rows_per_page: action.data,
    });
};

const set_staff_list_query = (state, action) => {
    return updateObject(state, {
        list_query: action.data,
    });
};

const set_staff_list_order_by = (state, action) => {
    return updateObject(state, {
        list_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STAFF_LIST_PAGE: return set_staff_list_page(state, action);
        case actionTypes.SET_STAFF_LIST_ROWS_PER_PAGE: return set_staff_list_rows_per_page(state, action);
        case actionTypes.SET_STAFF_LIST_QUERY: return set_staff_list_query(state, action);
        case actionTypes.SET_STAFF_LIST_ORDER_BY: return set_staff_list_order_by(state, action);
        default: return state;
    };
};

export default reducer;